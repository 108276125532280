<template>
    <div class="mx-auto px-md-5" style="max-width: 1200px;">
        <v-row justify="center" align-content-center class="white--text mb-15 mb-md-0">
            <v-col cols="12">
                <v-card class="elevation-0" dark color="secondary" :tile="$vuetify.breakpoint.smAndDown">
                    <v-card-text class="pt-2 pb-1 white--text">
                        <v-row dense class="mt-3 align-center">
                            <v-col cols="12" md="6">
                                <h2 class="display-1 pt-2 mb-md-1 text-center text-md-h3 shadowed" id="sign-up">Get Started</h2>
                                <p class="title lh-15 text-center mx-auto mt-1 mb-0 shadowed" style="max-width: 800px;">
                                    We&rsquo;re ready to have your back.
                                </p>
                                <v-row dense class="mb-3 mb-md-5 mt-3 mt-md-5">
                                    <v-col cols="12" md="12" class="text-center">
                                        <p class="subtitle-1 font-weight-medium mb-0 mb-md-3">
                                            <v-icon small color="primary" class="pr-1">far fa-dot-circle</v-icon> No Card Needed to Start<br>
                                            <v-icon small color="primary" class="pr-1">far fa-dot-circle</v-icon> Try a 20-day Trial Period for Free<br>
                                            <v-icon small color="primary" class="pr-1">far fa-dot-circle</v-icon> No Added Fees - All Features Included<br>
                                        </p>
                                        <p class="text-h3 mt-3 mt-md-5 mb-0 primary--text font-weight-bold shadowed">Only $3/month</p>
                                        <p class="body-2 mb-0">Cancel Anytime - Instantly, with no hassle or fees.</p>
                                    </v-col>
                                </v-row>
                                <v-divider class="hidden-md-and-up" />
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-row dense class="mx-md-8">
                                    <v-col cols="12">
                                        <p class="title text-center mb-0 mb-md-3">Create Your Account:</p>
                                        <v-alert class="mt-2" dense type="error" v-if="errors">
                                            {{ errors }}
                                        </v-alert>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field
                                            dark
                                            :autofocus="!$vuetify.breakpoint.smAndDown" 
                                            ref="name"  
                                            v-model="name"
                                            label="Your Name"
                                            type="text"
                                            outlined
                                            color="white"
                                            prepend-inner-icon="fa fa-user"
                                            class="hide-empty-msgs"
                                            placeholder="Ex: Alex (full name not required)"
                                            :success="name.length>0"
                                            :rules="[rules.required]"
                                            validate-on-blur
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="phone"
                                            outlined
                                            label="Your Phone Number"
                                            type="tel"
                                            prepend-inner-icon="fa fa-phone"
                                            class="hide-empty-msgs has-hint"
                                            prefix="+1"
                                            :success="phone.length == 14"
                                            placeholder="Ex: (555) 555 - 5555"
                                            :rules="[rules.required]"
                                            validate-on-blur
                                            v-mask="'(###) ###-####'"
                                            hint="US numbers only. Must be able to receive text messages."
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field
                                            ref="email"
                                            v-model="email"
                                            prepend-inner-icon="fa fa-envelope"
                                            :success="validEmail"
                                            outlined
                                            label="Your Email Address"
                                            type="email"
                                            class="hide-empty-msgs"
                                            placeholder="Ex: your.name@gmail.com"
                                            :rules="[rules.required, rules.email]"
                                            validate-on-blur
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="password"
                                            type="password"
                                            outlined
                                            prepend-inner-icon="fa fa-lock"
                                            class="hide-empty-msgs"
                                            label="Choose a Password"
                                            :success="password.length>5"
                                            :rules="[rules.required, rules.minlength]"
                                            validate-on-blur
                                            placeholder="Min. 6 Characters"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <p class="text-center caption">
                                            By providing your number, you agree to receive mobile messages from ImOkay.me. Message frequency varies. Message and data rates may apply. Reply STOP to end, HELP for help at any time. <a style="color: #fff; text-decoration: underline;" href="/privacy">Privacy Policy</a> <a  style="color: #fff; text-decoration: underline;" href="/terms">SMS Terms</a>.
                                        </p>
                                        <p class="text-center mb-2">
                                            <v-btn large rounded :loading="loggingIn" :disabled="!canSubmit" @click.native="signup" class="mt-3 mb-0 px-14" color="primary">Continue</v-btn>
                                        </p>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-divider class="mt-4 mb-3" />
                        <p class="text-center body-2 pb-0">
                            <router-link class="primary--text action-item" to="/login">Already have an account?</router-link> 
                        </p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
    
import { mapState, mapMutations } from 'vuex'

export default {
    name: 'AuthLogin',
    computed: {
        ...mapState(['user']),
        canLogin(){
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(this.email) && this.password && this.password.length > 5;
        },
        validEmail(){
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(this.email);
        },
        validPassword(){
            return this.password && this.password.length > 5;
        },
        validPasswordConfirm(){
            return this.passwordConfirmation && this.passwordConfirmation == this.password;
        },
        canSubmit(){
            return (
                this.validEmail
                && this.phone.length == 14
                && this.password
                && this.password.length > 5
            );
        }
    },
    data: () => ({
        loggingIn: false,
        email: '',
        password: '',
        name: '',
        phone: '',
        passwordConfirmation: '',
        errors: null,
        ready: false,
        rules: {
            required: value => !!value || 'Required.',
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || 'Invalid e-mail.'
            },
            minlength: value => value && value.length > 5 || 'Must be at least 6 characters.',
        }
    }),
    methods: {
        ...mapMutations(['setUser']),
        initData(){
            this.$appFn.setTitle("Signup - I'm Okay", true);
            if(this.user){
                this.email = this.user.email;
            }
        },
        signup(){ 
            this.loggingIn = true;
            this.errors = null;
            let request = {
                endpoint: '/auth/register', 
                method: 'post',
                data: {
                    name: this.name,
                    email: this.email,
                    password: this.password,
                    password_confirmation: this.password,
                    phone: this.phone
                },
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 201){
                    this.$appFn.trackEvent({action: 'sign_up', value: 0});
                    this.setUser(res.data);
                    this.$router.push('/app');
                }else if(res.status == 409){
                    // prompt to connect social account
                }else{
                    this.handleErrorResponse(res);
                }
            }).catch(()=>{
                this.errors = "Please try again.";
            }).finally(()=>{
                this.loggingIn = false;
            });
        },
        handleErrorResponse(res){
            this.errors = '';
            for(let i in res.data.errors){
                this.errors += res.data.errors[i];
            }
        },
    },
    mounted(){
		this.initData();
	},
    watch: {
    }
}
</script>